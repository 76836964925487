
import {useEffect, useRef, useState} from 'react';
import {useDisclosure, Button, Drawer, DrawerOverlay, 
  DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, // Input,
  DrawerFooter, keyframes
} from '@chakra-ui/react';
// import useSideNav from './SideNavFill';
import axios from 'axios';

const animation = keyframes`
  from {
    background: lightblue
  }
  to {
    background: '#bddfec'
  }

  10%, 90% {
    transform: translate3d(0, 1px, 0);
  }

  20%, 80% {
    transform: translate3d(0, -1px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(1px, 0, 0);
  }
`;

export default function SideNavBar( {handleTrend} ) {
  const animatedButton = `${animation} infinite 1.2s`;
  const {isOpen, onOpen, onClose} = useDisclosure()
  const btnRef = useRef()  

  const [trends, setTrends] = useState([])
  async function getData() {
    try {
      let res = await axios({
        method: 'GET',
        url: 'https://app.divining.app/trends',
        // params: {denom: queryDenom},
        // cancelToken: new axios.CancelToken(c => cancel = c)
      })
      return res.data
    }
    catch (err) {
      console.error(err)
    }
  }



  useEffect( () => {
    getData()
    .then( res => {
      setTrends(res)
    })
  }, [])

  return (
    <>
      <Button ref={btnRef}
        colorScheme='blue' color='white' onClick={onOpen} borderRadius='30px'
        _hover={{ animation: animatedButton}}
      >
        Trending
      </Button>
      <Drawer
        isOpen={isOpen}
        placement='right'
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent background='white' color='black'>
          <DrawerCloseButton />
          <DrawerHeader>Sermon Trends</DrawerHeader>
          <DrawerBody>
            {(trends && trends.length > 0) ? trends.map((x) => (<Button
              color='black' 
              colorScheme='blue' borderRadius='30px'
              _hover={{ animation: animatedButton}}
              key={x.phrase}
              onClick={ (e) => handleTrend(x.phrase)}
              >{x.phrase}</Button>))  : null}
            <Button
              color='black' key='default'
              colorScheme='blue' borderRadius='30px'
              _hover={{ animation: animatedButton}}
              onClick={ (e) => handleTrend("thanksgiving week")}
            >interdependent web</Button>                        
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={2} onClick={onClose}>
              Close
            </Button>            
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}