import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { extendTheme, ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import App from './App';

const theme = extendTheme({ 
  brand: {
    900: '#158cba', // topnav blue
    // #106a8c #0d5875 
    800: '#bddfec', // light blue 
    // '#20c997', // light green-blue
    700: '#BDB76B', // yellow-gold accent
    //'#8fd991' // lighter green
    600: 'DarkTurquoise'
  },
  initialColorMode: 'dark',
  useSystemColorMode: false,
})
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider resetCSS={false} theme={theme}>
    <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <App />
    </ChakraProvider>
  </React.StrictMode>
);
