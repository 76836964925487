import './sermon.css';
import { useState } from 'react'
import { Box, useDisclosure, Button, Text } from '@chakra-ui/react'
// import LiteYouTubeEmbed from 'react-lite-youtube-embed'; -- currently broken; need iframe for button to work

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const Sermon = ( {sermon} ) => {
  const { getDisclosureProps, getButtonProps } = useDisclosure()
  const buttonProps = getButtonProps() // transcript button
  const disclosureProps = getDisclosureProps()
  
  // on second use, you need to rename the disclosure functions
  const { 
    getDisclosureProps: getStartAudioDisclosureProps, 
    getButtonProps: getStartAudioProps 
  } = useDisclosure()

  const startAudioProps = getStartAudioProps() // mp3 button
  const startAudioDisclosureProps = getStartAudioDisclosureProps()

  const [show, toggleShowVideo] = useState(false)

  // {!show && <img src={`https://i1.ytimg.com/vi/${sermon.video_id}/mqdefault.jpg`} width="100%" height="360" alt="" />}
  // sermon.kind === 'Y' DOES NOT WORK but sermond.kind === 'M' does. WHY? because ?q=... didn't pass back kind/audio yet.

  function titleCase(str) {
    return str.replace(/(^|\s)\S/g, function(t) { return t.toUpperCase() });
  }

  return (
  <Box bg='white' p={4} color='CadetBlue' rounded='lg' shadow='Inner'>
    <Box as='h3' color='Black'>{sermon.title} | {sermon.channel_title}</Box>

    { (!show && sermon.video_id) 
      ? <img src={`https://i1.ytimg.com/vi/${sermon.video_id}/mqdefault.jpg`} width="100%" height="360" alt="" />
      : null
    }

    {!show && sermon.kind === 'M' && sermon.thumbnail !== "" &&
    <img src={sermon.thumbnail} width="100%" height="512" alt="" />}

    {show && <Box key={(sermon.video_id || sermon.audio) + "-video"} >
      <iframe className="lazy" 
      title={sermon.title}
      data-title={sermon.title}
      allowFullScreen
      width="100%"
      height="360"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      src={`https://www.youtube.com/embed/${sermon.video_id}?origin=https://divining.app&showinfo=0&&enablejsapi=1&widgetid=1&color=white&modestbranding=1&rel=0&sameSite=Lax`}
       frameBorder="0"></iframe>
    </Box>}

    { sermon.video_id ?
    <Button 
      onClick={() => toggleShowVideo(!show)}
      colorScheme='blue'
      type="teal" size="sm"
      color='white'
      borderRadius='none'
    >{show ? "Hide" : "Video"}</Button>: null }

    { sermon.kind === 'M' && sermon.audio && sermon.audio !== '' ?
    /*<Button {...startAudioProps}
      colorScheme='blue'
      type="teal" size="sm"
      color='white'
      borderRadius='none'
    >Audio</Button> + <br/> + */
    <AudioPlayer    
      src={sermon.audio}
      onPlay={e => console.log("onPlay")}
      layout='horizontal'
      showJumpControls={false}
      showSkipControls={false}
      progressJumpStep={60000}
      progressUpdateInterval={200}
      timeFormat={'hh:mm:ss'}
      customAdditionalControls={[]}
    />
    : null }

    { sermon.captions === '' || !sermon.captions ? null :
    <Button {...buttonProps}
      colorScheme='blue'
      type="teal" size="sm"
      color='white'
      borderRadius='none'
    >Transcript</Button>}

    <span> {sermon.topic ? titleCase(sermon.topic): null} {(sermon.denomination && sermon.topic) ? '| ' + titleCase(sermon.denomination): null} </span>

    <Text {...startAudioDisclosureProps} mt={4}>
      <a href={sermon.audio} target="_blank" rel="noreferrer">{sermon.audio}</a>
    </Text>

    { !disclosureProps.hidden ? null :
    <Text
      overflowY="auto"
      maxH="15em"
      fontWeight='light'
      lineHeight='tight'
      fontSize='md'    
      sx={{
        '&::-webkit-scrollbar': {
          width: '12px',
          borderRadius: '4px',
          backgroundColor: `rgba(0, 0, 0, 0.15)`,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: `rgba(0, 0, 0, 0.15)`,
        },
      }}
      >
      { !sermon.description || sermon.description === ''
        ? sermon.description
        : sermon.captions.substr(0,200) + '...'
      }
    </Text>
    }

    <Text {...disclosureProps} mt={4}
        overflowY="auto"
        overflowX="auto"
        maxH="30em"
        h="100%"
        color="SlateGrey"
        fontWeight='light'
        lineHeight='tight'
        fontSize='md'
        sx={{
          '&::-webkit-scrollbar': {
            width: '12px',
            borderRadius: '4px',
            backgroundColor: `rgba(0, 0, 0, 0.15)`,
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: `rgba(0, 0, 0, 0.15)`,
          },
        }}  
      >
      {sermon.captions}
    </Text>
  </Box>
  )
}

export default Sermon
