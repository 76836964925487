import { useState, useRef, useCallback } from 'react';
import { Box, SimpleGrid, HStack } from '@chakra-ui/react';
// Ref is a value that persists between each rendering; until page reload
// import { nanoid } from 'nanoid';
// import FavoritesList from './components/FavoritesList';
import Sermon from './components/Sermon';
import useSermonSearch from  './components/SermonSearch';
import TopNavBar from './components/TopNavBar';

// import RandomPassword from './components/RandomPassword';
// import YouTube from 'react-youtube';
// BsEmojiSmile FaMountain, FaBlenderPhone

var prevPageNumber = 1

const App = () => {
  /*
  // tracking saved sermons
  const [favorites, setFavorites] = useState([]);
  const addFav = (text) => {
    // make a new Note object
    const date = new Date();
    const newNote = {
      id: nanoid(),
      text: text,
      date: date.toLocaleDateString()
    }
    const newNotes = [...favorites, newNote]
    setFavorites(newNotes)
  }
  */

  // infinite scroll
  const [query, setQuery] = useState('')
  const [pageNumber, setPageNumber] = useState('')
  const [limit, setLimit] = useState(10)
  const [queryDenom, setQueryDenom] = useState('')
  const [queryTrend, setQueryTrend] = useState('')
  const {
      sermons,
      hasMore,
      loading,
      error,
      // numFound
  } = useSermonSearch(query, pageNumber, limit, queryDenom, queryTrend) // initial query: return 10 instead of 20 on first pageload; faster
  
  const observer = useRef() // reference for last sermon in each page
  const lastSermonElementRef = useCallback( node => {
      if (loading) return
      if (observer.current) observer.current.disconnect()
      observer.current = new IntersectionObserver(entries => {
          if (entries[0].isIntersecting && hasMore) { // intersecting: is the current node on the page somewhere
              // console.log('Visible')
              // because you only want scroll to trigger when last element is visible
              setPageNumber( prevPageNumber++)
          }
      })
      if (node) observer.current.observe(node)
  }, [loading, hasMore])

  function handleSearch(event) {
      setQuery( event.target.value )
      setQueryTrend('')
      setPageNumber(1)
      setLimit(10)
      // console.log("handleSearch %s %s %s",query, pageNumber, limit)
  }

  function handleDenom(value) {
    setQueryDenom(value)
    setPageNumber(1)
    setLimit(10)
    // console.log('---',value, '---', query)
  }

  function handleTrend(value) {
    setQueryTrend(value)
    setQuery('')
    setPageNumber(1)
    setLimit(10)
    // console.log('---',value)
  }


  function showQuery(query) {
      if (query === "" && queryTrend === "") {
        return 'Loading latest content...'
      } else if (queryTrend !== "") {
        return `Loading ${queryTrend} content...`
      } else {
        return `Loading ${query} content...`
      }
  }

  

  return (
  <>

    <TopNavBar query={query} handleSearch={handleSearch} handleDenom={handleDenom} handleTrend={handleTrend} /> {/* numFound={numFound} */}

  <HStack>
  <SimpleGrid minChildWidth={'420px'} spacing={5} className="container">  {/* columns={3} */}
        { sermons.map( (sermon,index) => {
            if (sermons.length === index + 10) { // using 10 so that we trigger this 10 elements before end of list is seen.
                return <Box ref={lastSermonElementRef} key={ index + (sermon.video_id || sermon.audio)}>
                    {sermon.title}<br />-----------------
                </Box>
            } else {
                return <Box key={ index + (sermon.video_id || sermon.audio)}> <Sermon sermon={sermon} /> </Box>
            }

        })}
        <Box color='white'>{loading && showQuery(query)}</Box>
        <Box>{error && 'Error'}</Box>
  </SimpleGrid>
    <Box className="sidebar-right">
      <div className="notes-list">
        {/*  <FavoritesList favorites={favorites} handleAddFavorite={addFav} />      */}
        &nbsp;
      </div>
    </Box>
  </HStack>
  </>
  )
}

export default App;
