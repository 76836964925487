import './topnavbar.css';
import { BsSearch } from "react-icons/bs";
import { Input, Text, Select } from "@chakra-ui/react";
import { FaChurch, FaSynagogue, FaMosque, FaToriiGate } from "react-icons/fa";
// FaVolumeUp, FaAngleDoubleDown
// FaPlaceOfWorship FaPastafarianism
// import { MdAccountCircle } from "react-icons/md";
import {
  Box,
  Flex,
  Avatar,
  // Link,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  HStack,
  Center,
} from '@chakra-ui/react';
import SideNavBar from './SideNavBar';

export default function TopNavBar( {query, handleSearch, handleDenom, handleTrend } ) {
  return (
  <>
    <Flex
      alignItems={'center'}
      justifyContent={'space-between'} 
      w="100%"
      mb={4}
      bg={useColorModeValue('#158cba;', '#158cba;')}
      direction={{ base: 'column', md:'row'}}      
    >
      <Text color='white' ml={[2,2,4,8]} fontSize={{base:'1em', md:'1em', lg:'1.5em', xl:'2em'}}>
        <FaToriiGate/> Divining.app <FaChurch/><FaMosque/><FaSynagogue/>        
      </Text>
            
        <HStack direction={'row'} spacing={4} className="searchBar" minWidth={80}>
          <BsSearch className="searchIcon" />
          <Input bg="white"
            border="0"
            size='md'
            type="flushed"
            color="black"
            fontWeight="bolder"     
            fontSize="lg"   
            className="searchInput" 
            value={query}
            onChange={handleSearch}            
            pe={0}            
            >
          </Input>  
          <Flex pr={1}>
          <SideNavBar handleTrend={handleTrend} />      
          </Flex>
        </HStack>
      

      <Stack direction={'row'} spacing={4}>
        <Box minW={40} _hover={{ bg: 'DarkTurquoise' }}>
          <Select onChange={(e) => handleDenom(e.target.value)}>
            <option key='default-none' value=''>Denomination</option>
            <option key='uu' value='uu'>UU</option>
            <option key='baptist' value='baptist'>Baptist</option>
            <option key='catholic' value='catholic'>Catholic</option>
            <option key='episcopal' value='episcopal'>Episcopal</option>
            <option key='umc' value='umc'>UMC</option>
            <option key='lutheran' value='lutheran'>Lutheran</option>
            <option key='ucc' value='ucc'>UCC</option>
            <option key='quaker' value='quaker'>Quaker</option>
            <option key='mennonite' value='mennonite'>Mennonite</option>
          </Select> 
        </Box>
        
        <Box pr={4}>
        <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}>
            <Avatar
              size={'sm'}
              src={'https://avatars.dicebear.com/api/male/username.svg'}
            />
          </MenuButton>

          <MenuList alignItems={'center'}>
            <br />
            <Center>
              <Avatar
                size={'2xl'}
                src={'https://avatars.dicebear.com/api/male/username.svg'}
              />
            </Center>
            <br />
            <Center>
              <p>Username</p>
            </Center>
            <br />
            <MenuDivider />
            <MenuItem>Saved Content</MenuItem>
            <MenuItem>Settings</MenuItem>            
            <MenuItem>Logout</MenuItem>
          </MenuList>
        </Menu>
        </Box>
      </Stack>
    </Flex>
  </>)
}