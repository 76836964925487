import { useEffect, useState } from 'react'
import axios from 'axios'

export default function useSermonSearch(query, pageNumber, limit=20, queryDenom='', queryTrend=null) {
  const [ loading, setLoading] = useState(true)
  const [ error, setError ] = useState(false)
  const [ sermons, setSermons ] = useState([])
  const [ hasMore, setHasMore ] = useState(false) // auto-paginator
  // const [ numFound, setNumFound ] = useState(0) // backend no longer sends a total count

  useEffect( () => {
    setSermons([]) // resets list after each query
  }, [query, queryDenom, queryTrend])

  useEffect( () => {
    setLoading(true)
    setError(false)
    if (query !== "" || queryTrend !== "") {
      console.log({q: query, page: pageNumber, limit: limit, denom: queryDenom, like: queryTrend})
    }
    let cancel
    axios({
      method: 'GET',
      // url: 'http://localhost:3001/', # on same server
      // url: 'http://157.230.180.105:3001/', # not secure
      url: 'https://app.divining.app',
      params: {q: query, page: pageNumber, limit: limit, denom: queryDenom, like: queryTrend},
      cancelToken: new axios.CancelToken(c => cancel = c)
    }).then( res => {            
      //console.log(res.data, numFound)
      //if (res.data.numFound > 0) {
      //    setNumFound(res.data.numFound)
      //} else setNumFound(0)
      setSermons( prevSermons => {
          // Set drops duplicates
          return [...new Set([...prevSermons, ...res.data.map( sermon => sermon )])]
    })
      setHasMore(res.data.length > 0)
      setLoading(false)
    }).catch( e => {
      if (axios.isCancel(e)) return
      setError(true)
    })
    return () => cancel()
  }, [query, pageNumber, limit, queryDenom, queryTrend] )
  return { loading, error, sermons, hasMore }
}
